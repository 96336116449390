import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loader from '../../Loader/loader';
import CarouselTwo from '../../Carousel2/carouselTwo';
import TeamCarousel from '../../TeamCarousel/teamCarousel';
import './about.css';
import Aboutimage from "../../../Assets/Images/Gallery/Component 1.png";

const About = () => {
  const [loading, setLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false); // State for toggling "Learn More" section
  const learnMoreRef = useRef(null); // Reference for the "Learn More" section

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(loadingTimeout);
  }, []);

  const handleLearnMoreToggle = () => {
    setIsExpanded(prev => !prev); // Toggle expansion state

    // Scroll to the "Learn More" section smoothly
    if (!isExpanded && learnMoreRef.current) {
      learnMoreRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className='about'>
      {loading ? (
        <Loader />
      ) : (
        <div className="about-container">
          <CarouselTwo />

          <div className='container-xxl py-5'>
            <div className="container row">
              {/* Text and Image Container */}
              <div className="col-md-6 d-flex justify-content-end">
                <img
                  src={Aboutimage}
                  alt="Team"
                  className="img-fluid"
                />
              </div>
              <div className="col-md-6 d-flex align-items-center">
                <div className='text-container'>
                  <h4 className="display-6 mb-5 f-abt">We Help People In Need Around The World</h4>
                  <p className="f-abt-s">
                    Little Star Charity is an NGO founded in 2019 Under the leadership of Mr. Andy Eyram Azumah Mensah, Sedi humado, Richmond Nsowah and a group of friends. Their journey began with a year-group project after completing high school, which led to the creation of Little Star. Their first initiative, "Project FEED 50," was a significant success and deeply inspired the founding members to commit to the cause.<br /><br />

                    On 22nd November 2023, we officially registered as an NGO in Ghana, marking a pivotal step towards becoming a recognized legal entity. Since then, <br /> Little Star has gradually grown, taking small yet impactful steps to expand our reach and influence.<br /><br />

                    Our aim is to assist underprivileged children by providing educational support, healthcare services, essential resources, and making a meaningful difference in their lives.
                  </p>

                  {/* Learn More Button */}
                  <button
                    className="btn btn-primary mt-3 mb-5 no-radius"
                    onClick={handleLearnMoreToggle}
                  >
                    {isExpanded ? "Show Less" : "Learn More"}
                  </button>

                  {/* Learn More Content */}
                  <div
                    ref={learnMoreRef} // Assign reference to the "Learn More" section
                    className={`learn-more-content ${isExpanded ? "expanded" : "collapsed"}`}
                    style={{ overflow: 'hidden', transition: 'max-height 0.5s ease' }} // Adjusted transition duration
                  >
                    <h5 className="mt-4 f-abt-l">Vision</h5>
                    <p className='f-abt-s'>Creating a world where every child has the opportunity to thrive and reach their full potential.</p>

                    <h5 className="mt-4 f-abt-l">Mission</h5>
                    <p className='f-abt-s'>Little Star Charity is dedicated to empowering today's children to assume their rightful positions as the leaders of tomorrow. Our mission is to ensure they have access to food, shelter, education, opportunities, and life-altering experiences necessary for their success and to help create a better world for all.</p>

                    <h5 className="mt-4 f-abt-l">Strategy</h5>
                    <p className='f-abt-s'>We empower communities through sustainable development, education, and health initiatives, fostering self-reliance and long-term growth.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Board of Trustees Section */}
          <div className="background-texture-section">
            <div className="container text-container">
              <h1 className="display-4 mb-1 text-center fw-bolder abt-fs">Board of Executives</h1>
            </div>
          </div>

          {/* Founders Carousel Start */}
          <div className='container-xxl py-5'>
            <TeamCarousel />
          </div>
          {/* Founders Carousel End */}
        </div>
      )}
    </div>
  );
};

export default About;
