import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Pages/HomePage/home';
import About from './components/Pages/AboutPage/about';
import Donate from './components/Pages/DonatePage/donate';
import Contact from './components/Pages/ContactPage/contact';
import Gallery from './components/Pages/GalleryPage/gallery';
import Impact from './components/Pages/OurImpacts/impact';
import ProjectDetails from './components/ImpactDetails/ProjectDetails'; // Import the ProjectDetails component
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

// Import libraries
import WOW from 'wowjs'; // WOW.js for animations
import 'jquery.easing'; // jQuery Easing
import 'owl.carousel/dist/assets/owl.carousel.css'; // Owl Carousel CSS
import 'owl.carousel'; // Owl Carousel JS
import Parallax from 'parallax-js'; // Parallax.js

function App() {
  useEffect(() => {
    // Initialize WOW.js for animations
    new WOW.WOW().init();

    // Owl Carousel initialization (if using jQuery-based Owl Carousel)
    window.$('.owl-carousel').owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      items: 1,
    });

    // Initialize Parallax.js
    const scene = document.getElementById('scene');
    if (scene) {
      new Parallax(scene);
    }
  }, []);

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/impact" element={<Impact />} />
          <Route path="/projects/:id" element={<ProjectDetails />} /> {/* Add the ProjectDetails route */}
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
