import React from 'react';
import image3 from '../../Assets/Images/Gallery/gallery-43.JPG';
// import image2 from '../../Assets/Images/carousel-2.jpg';
// import image3 from '../../Assets/Images/carousel-3.jpg';
import '../CarouselDonate/CarouselDonate.css';

const images = [
  { src: image3, alt: 'Image 1', text: 'We Help People In Need Around The World', link: '#' },
//   { src: image2, alt: 'Image 2', text: 'We Help People In Need Around The World', link: '/learn-more-2' },
//   { src: image3, alt: 'Image 3', text: 'We Help People In Need Around The World', link: '/learn-more-3' },
];

const Slider = () => {
  return (
    <div className="CarouselDonate">
      <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          {images.map((_, index) => (
            <button
              key={index}
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to={index}
              className={index === 0 ? "active" : ""}
              aria-current={index === 0 ? "true" : "false"}
              aria-label={`Slide ${index + 1}`}
            ></button>
          ))}
        </div>
        <div className="carousel-inner">
          {images.map((image, index) => (
            <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
              <img src={image.src} className="d-block w-100 h-14" alt={image.alt} />
              <div className="carousel-caption  d-md-block">
                <h1 className="display-4 text-white mb-5 animated slideInDown">Support Our Initiative</h1>
                {/* <h5>{image.alt}</h5> */}
                {/* <p>{image.text}</p> */}
                {/* <a href={image.link} className="btn btn-light">Learn More</a> */}
              </div>

            </div>
          ))}
        </div>
        {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button> */}
      </div>
    </div>
  );
};

export default Slider;
