import React, { useState, useEffect, useRef } from 'react';
import './testimonials.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import gallery1 from "../../Assets/Images/Testimonials/test-1.png";
import gallery2 from "../../Assets/Images/Testimonials/Kweku.png";
import gallery3 from "../../Assets/Images/Testimonials/Adoma.jpg";
import gallery4 from "../../Assets/Images/Testimonials/Evans.jpg";
import gallery5 from "../../Assets/Images/Testimonials/Ida.jpg";
import gallery6 from "../../Assets/Images/Testimonials/Selasi.jpg";
import gallery7 from "../../Assets/Images/Testimonials/Gamali.jpg";
import gallery8 from "../../Assets/Images/Testimonials/Efua Aidoo.jpg";

const Testimonials = () => {
  const testimonialsData = [
    {
      id: 1,
      name: 'Beryl Bempong',
      title: 'Member',
      quote: 'It is been a wonderful journey and I am so glad I joined this charity',
      src: gallery1,
    },
    {
      id: 2,
      name: 'Kweku Seyki Cann',
      title: 'Member',
      quote: 'Putting smiles on the faces of less privileged youth has been the best experience.',
      src: gallery2,
    },
    {
      id: 3,
      name: 'Adomaa Sasu-Boamah',
      title: 'Member',
      quote: "Being part of this charity has opened my eyes to the power of compassion. Together, we can change lives.",
      src: gallery3,
    },
    {
      id: 4,
      name: 'Evans Hyde',
      title: 'Member',
      quote: "Connecting with great minds to help less privileged youth",
      src: gallery4,
    },
    {
      id: 5,
      name: 'Ida Maxine Cudjoe',
      title: 'Member',
      quote: "My experience has been truly remarkable. I love seeing the smiles on the kids faces and the impact we’ve made even in small ways has empowered these children.",
      src: gallery5,
    },
    {
      id: 6,
      name: 'Selasi Ansre',
      title: 'Member',
      quote: "it has been inspiring how we all come together to help underprivileged children in our community",
      src: gallery6,
    },
    {
      id: 7,
      name: 'Gameli Komla Agbogah',
      title: 'Member',
      quote: "Little star charity goes beyond just giving, we truly care about our communities",
      src: gallery7,
    },
    {
      id: 8,
      name: 'Efua Aidoo',
      title: 'Member',
      quote: "My experience has been overwhelmingly positive, thanks to the warm welcome from the president and LSC members, and I eagerly anticipate the impactful projects this great charity will undertake.",
      src: gallery8,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonialsData.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonialsData.length) % testimonialsData.length);
  };

  return (
    <div className="testimonials-container">
      <h2 className="testimonials-title fw-bolder">"Trusted by thousands."</h2>
      <div className="testimonial-card">
        <img className="testimonial-image" src={testimonialsData[currentIndex].src} alt={testimonialsData[currentIndex].name} />
        <h3 className="testimonial-name">{testimonialsData[currentIndex].name}</h3>
        <p className="testimonial-title">{testimonialsData[currentIndex].title}</p>
        <p className="testimonial-quote">"{testimonialsData[currentIndex].quote}"</p>
      </div>
      {/* Bootstrap Arrows for navigation */}
      <div className="testimonial-navigation">
        <button className="btn btn-light prev-arrow" onClick={handlePrev}>&#10094;</button>
        <button className="btn btn-light next-arrow" onClick={handleNext}>&#10095;</button>
      </div>
    </div>
  );
};

export default Testimonials;
