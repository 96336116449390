import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { FaAngleDown, FaCaretUp } from 'react-icons/fa';
import logo from '../../Assets/Logos/LittleStar-logo.png';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Track dropdown state

  // Toggle mobile menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close mobile menu and dropdown
  const closeMenu = () => {
    setIsMenuOpen(false);
    setIsDropdownOpen(false);
  };

  // Toggle dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev); // Toggle dropdown state
  };

  // Detect scroll and set sticky navbar
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
        setShowScrollButton(true);
      } else {
        setIsSticky(false);
        setShowScrollButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Scroll to section functionality
  // const scrollToSection = (className) => {
  //   const section = document.querySelector(`.${className}`);
  //   if (section) {
  //     section.scrollIntoView({ behavior: 'smooth' });
  //   }
  //   closeMenu();
  // };

  return (
    <nav className={`navbar ${isSticky ? 'sticky' : ''}`}>
      <div className="logo">
        <span>
          <img src={logo} alt="Logo" />
        </span>
        <Link to="/" onClick={closeMenu}>LITTLE STAR</Link>
        <span>CHARITY</span>
      </div>
      <ul className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
        <li><Link to="/" onClick={closeMenu}>Home</Link></li>
        <li className={`dropdown ${isDropdownOpen ? 'active' : ''}`}>
          <Link to="#" onClick={toggleDropdown}>
            What We Do
            <FaAngleDown className="arrow-icon" />
          </Link>
          <ul className="dropdown-content">
            <li>
              <Link class="lt-drop" to="/impact"  onClick={closeMenu}>
                Our Impacts
              </Link>
            </li>
            <li><Link class="lt-drop" to="#" onClick={closeMenu}>News & Blog</Link></li>
          </ul>
        </li>
        <li><Link to="/gallery" onClick={closeMenu}>Gallery</Link></li>
        <li><Link to="/about" onClick={closeMenu}>About Little Star</Link></li>
        <li><Link to="/contact" onClick={closeMenu}>Contact</Link></li>
        <li><Link to="/donate" className="donate-btn" onClick={closeMenu}>Donate Now</Link></li>
      </ul>

      {/* Close button for mobile menu */}
      {isMenuOpen && (
        <span className="close-btn" onClick={toggleMenu}>&times;</span>
      )}

      {/* Hamburger menu icon for mobile */}
      <div className="hamburger-menu" onClick={toggleMenu}>
        {!isMenuOpen && (
          <>
            <span className="bar"></span>
            <span className="bar"></span>
          </>
        )}
      </div>

      {/* Scroll-to-top button */}
      {showScrollButton && (
        <button className="scroll-button" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          <FaCaretUp />
        </button>
      )}
    </nav>
  );
};

export default Navbar;
