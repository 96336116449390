import React, { useState } from 'react';
import { FaMapMarkerAlt, FaEnvelope, FaPhone } from 'react-icons/fa';
import CardTexture from '../../../Assets/CardTexture/Card-Texture.png';
import CardTexture2 from '../../../Assets/CardTexture/Card-Texture2.png';
import "./contact.css"

const ContactPage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { firstName, lastName, email, phone, message } = formData;

    // Construct the mailto URL
    const mailtoLink = `mailto:littlestarcharitygh@gmail.com?subject=Contact from ${firstName} ${lastName}&body=Name: ${firstName} ${lastName}%0AEmail: ${email}%0APhone: ${phone}%0A%0AMessage: ${message}`;

    // Redirect to the mailto link
    window.location.href = mailtoLink;

    // Optionally, reset the form after submission
    setFormData({ firstName: '', lastName: '', email: '', phone: '', message: '' });
  };

  return (
    <div className="contact-box container">
      {/* Top section with title and description */}
      <div className="top-section">
        <div>
          <h1 className='fw-bolder fs-8'>Contact Us</h1>
          <p>Any questions or remarks? Just write us a message!</p>
        </div>
      </div>

      {/* contact info and form */}
      <div className="bottom-section">
        {/* Left side: Contact Information */}
        <div className="contact-info" style={{ backgroundImage: `url(${CardTexture})`, objectFit: 'cover' }}>
          <div className='info-box'>
            <h3 className='fs-20 fw-bolder text-white'>Contact Information</h3>
            <p className='text-white'>Contact us for more info. Thank you </p>
          </div>
          <div className='info-item text-white'>
            <p>
              <FaMapMarkerAlt style={{ marginRight: '10px' }} />
              Accra, Ghana
            </p>
            <p>
              <FaEnvelope style={{ marginRight: '10px' }} />
              littlestarcharitygh@gmail.com
            </p>
            <p>
              <FaPhone style={{ marginRight: '10px' }} />
              +233 530 504 174
            </p>
          </div>
        </div>

        {/* Right side: Input Form */}
        <div className="contact-form" style={{ backgroundImage: `url(${CardTexture2})`, backgroundRepeat: 'no-repeat', objectFit: 'cover' }}>
          <form className='form-item' onSubmit={handleSubmit}>
            {/* First Name and Last Name */}
            <div className="form-row">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control-contact"
                  name="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
                <hr />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control-contact"
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
                <hr />
              </div>
            </div>

            {/* Email and Phone Number */}
            <div className="form-row">
              <div className="form-group">
                <input
                  type="email"
                  className="form-control-contact"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <hr />
              </div>
              <div className="form-group">
                <input
                  type="tel"
                  className="form-control-contact"
                  name="phone"
                  placeholder="Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                />
                <hr />
              </div>
            </div>

            {/* Message */}
            <div className="form-group">
              <textarea
                className="form-control-contact"
                name="message"
                rows="4"
                placeholder="Your Message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
              <hr />
            </div>

            {/* Submit Button */}
            <div className="form-group">
              <button type="submit" className="btn-1">Send Message</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
