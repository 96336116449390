import React from 'react';
import './loader.css'; // Import the CSS file for styling
import logo from '../../Assets/Logos/LittleStar-logo.png'; // Update the path to your logo image

const Loader = () => {
  return (
    <div className='loader'>

   
    <div className="loader-container">
      <img src={logo} alt="Loading..." className="logo-spinner" />
    </div>
    </div>);
};

export default Loader;
