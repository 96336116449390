import React from 'react';
import image1 from '../../Assets/Images/carousel-1.jpg';
import image2 from '../../Assets/Images/gallery-30.JPG';
import image3 from '../../Assets/Images/carousel-3.jpg';
import image4 from '../../Assets/Images/Carousel-1-1.jpg';
// import image5 from '../../Assets/Images/gallery-30.JPG';
import image6 from '../../Assets/Images/gallery-43.JPG';
import image7 from '../../Assets/Images/gallery-56.JPG';
import image8 from '../../Assets/Images/gallery-62.JPG';
import '../Carousel/carousel.css';

const images = [
  { src: image1, alt: 'Image 1', text: 'Description for Image 1', link: '#' },
  { src: image2, alt: 'Image 2', text: 'Description for Image 2', link: '#'},
  { src: image3, alt: 'Image 3', text: 'Description for Image 3', link: '#' },
  { src: image4, alt: 'Image 4', text: 'Description for Image 3', link: '#' },
  // { src: image5, alt: 'Image 5', text: 'Description for Image 1', link: '/learn-more-1' },
  { src: image6, alt: 'Image 6', text: 'Description for Image 2', link: '#'},
  { src: image7, alt: 'Image 7', text: 'Description for Image 3', link: '#' },
  { src: image8, alt: 'Image 8', text: 'Description for Image 3', link: '#' },
];

const Slider = () => {
  return (
    <div className="carousel">
      <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          {images.map((_, index) => (
            <button
              key={index}
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to={index}
              className={index === 0 ? "active" : ""}
              aria-current={index === 0 ? "true" : "false"}
              aria-label={`Slide ${index + 1}`}
            ></button>
          ))}
        </div>
        <div className="carousel-inner">
          {images.map((image, index) => (
            <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
              <img src={image.src} className="d-block w-100" alt={image.alt} />
              <div className="carousel-caption  d-md-block">
                <h1 className="display-4 text-white mb-5 animated slideInDown">Let's Change The World With Humanity</h1>
                {/* <h5>{image.alt}</h5>
                <p>{image.text}</p> */}
                <a href={image.link} className="btn btn-light">Learn More</a>
              </div>

            </div>
          ))}
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Slider;
