import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Banner/banner.css'; 

const Banner = () => {
  return (
 <div className='banner'>
    <div className="banner-container">
      {/* Overlay */}
      <div className="overlay"></div>

      {/* Banner Section with background image */}
      <div className="jumbotron text-center ">
        <h1 className="display-4 text-white fw-bolder">Support Our Initiative</h1>
        <p className="lead text-white">Donate to Little Star Charity Foundation.</p>
        <hr className="my-4 bg-light" />
        <p className="mb-4 text-white">Every donation helps bring us one step closer to achieving our goals.</p>
        {/* Donate Button */}
        <a className="btn btn-light btn-lg" href="/donate" role="button">
          Donate Now
        </a>
      </div>
    </div>
    </div>
  );
};

export default Banner;
