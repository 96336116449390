import React, { useState, useEffect } from 'react';
import "./gallery.css"; 
import Loader from './../../Loader/loader'; // Import your Loader component

import gallery1 from "../../../Assets/Images/Gallery/gallery-44.JPG";
import gallery2 from "../../../Assets/Images/Gallery/gallery-46.JPG";
import gallery3 from "../../../Assets/Images/Gallery/gallery-25.JPG";
import gallery4 from "../../../Assets/Images/Gallery/gallery-4.jpg";
import gallery5 from "../../../Assets/Images/Gallery/gallery-5.jpg";
import gallery6 from "../../../Assets/Images/Gallery/gallery-27.JPG";
import gallery7 from "../../../Assets/Images/Gallery/gallery-7.jpg";
import gallery8 from "../../../Assets/Images/Gallery/gallery-8.jpg";
import gallery9 from "../../../Assets/Images/Gallery/gallery-9.jpg";
import gallery10 from "../../../Assets/Images/Gallery/gallery-10.jpg";
import gallery11 from "../../../Assets/Images/Gallery/gallery-11.jpg";
import gallery12 from "../../../Assets/Images/Gallery/gallery-12.JPG";
import gallery13 from "../../../Assets/Images/Gallery/gallery-43.JPG";
import gallery14 from "../../../Assets/Images/Gallery/gallery-14.JPG";
import gallery15 from "../../../Assets/Images/Gallery/gallery-15.JPG";
import gallery16 from "../../../Assets/Images/Gallery/gallery-42.JPG";
import gallery17 from "../../../Assets/Images/Gallery/gallery-17.JPG";
import gallery18 from "../../../Assets/Images/Gallery/gallery-18.JPG";
import gallery19 from "../../../Assets/Images/Gallery/gallery-19.JPG";
import gallery20 from "../../../Assets/Images/Gallery/gallery-20.JPG";
import gallery21 from "../../../Assets/Images/Gallery/gallery-57.JPG";
import gallery22 from "../../../Assets/Images/Gallery/gallery-22.JPG";
import gallery23 from "../../../Assets/Images/Gallery/gallery-40.JPG";
import gallery24 from "../../../Assets/Images/Gallery/gallery-24.JPG";
import gallery25 from "../../../Assets/Images/Gallery/gallery-63.JPG";
import gallery26 from "../../../Assets/Images/Gallery/gallery-26.JPG";
import gallery27 from "../../../Assets/Images/Gallery/gallery-41.JPG";
import gallery28 from "../../../Assets/Images/Gallery/gallery-28.JPG";
import gallery29 from "../../../Assets/Images/Gallery/gallery-29.JPG";
import gallery30 from "../../../Assets/Images/Gallery/gallery-30.JPG";
import gallery45 from "../../../Assets/Images/Gallery/gallery-45.JPG";
import gallery66 from "../../../Assets/Images/Gallery/gallery66.jpg";

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);

  const images =[
    { id: 1, src: gallery1 },
    { id: 2, src: gallery2 },
    { id: 3, src: gallery3 },
    { id: 4, src: gallery4 },
    { id: 5, src: gallery5 },
    { id: 6, src: gallery6 },
    { id: 7, src: gallery7 },
    { id: 8, src: gallery8 },
    { id: 9, src: gallery9 },
    { id: 10, src: gallery10 },
    { id: 11, src: gallery11 },
    { id: 12, src: gallery12 },
    { id: 13, src: gallery13 },
    { id: 14, src: gallery14 },
    { id: 15, src: gallery15 },
    { id: 16, src: gallery16 },
    { id: 17, src: gallery17 },
    { id: 18, src: gallery18 },
    { id: 19, src: gallery19 },
    { id: 20, src: gallery20 },
    { id: 21, src: gallery21 },
    { id: 22, src: gallery22 },
    { id: 23, src: gallery23 },
    { id: 24, src: gallery24 },
    { id: 25, src: gallery25 },
    { id: 26, src: gallery26 },
    { id: 27, src: gallery27 },
    { id: 28, src: gallery28 },
    { id: 29, src: gallery29 },
    { id: 30, src: gallery30 },
    { id: 45, src: gallery45 },
    { id: 66, src: gallery66 }
  ];
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const openModal = (index) => {
    setCurrentIndex(index);
    setSelectedImage(images[index]);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const showNextImage = (e) => {
    e.stopPropagation();
    const newIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(newIndex);
    setSelectedImage(images[newIndex]);
  };

  const showPrevImage = (e) => {
    e.stopPropagation();
    const newIndex = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(newIndex);
    setSelectedImage(images[newIndex]);
  };

  // Handle swipe gestures
  const handleTouchStart = (e) => {
    setTouchStartX(e.changedTouches[0].screenX);
  };

  const handleTouchEnd = (e) => {
    setTouchEndX(e.changedTouches[0].screenX);
    handleSwipe();
  };

  const handleSwipe = () => {
    if (touchStartX - touchEndX > 50) {
      // Swipe left (show next image)
      showNextImage({ stopPropagation: () => {} });
    }
    if (touchEndX - touchStartX > 50) {
      // Swipe right (show previous image)
      showPrevImage({ stopPropagation: () => {} });
    }
  };

  return (
    <div className="gallery">
      {loading ? (
        <Loader /> 
      ) : (
        <div className="gallery-container">
          <h2 className="gallery-title">Our Events & Impact</h2>
          <div className="gallery-grid">
            {images.map((image, index) => (
              <div
                className={`gallery-item ${image.id % 3 === 0 ? 'large' : ''}`}
                key={image.id}
                onClick={() => openModal(index)}
              >
                <img src={image.src} alt={`Event ${image.id}`} className="gallery-image" />
              </div>
            ))}
          </div>

          {selectedImage && (
            <div
              className="modal"
              onClick={closeModal}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
            >
              <span className="close" onClick={closeModal}>&times;</span>
              <img className="modal-content" src={selectedImage.src} alt={`Event ${selectedImage.id}`} />
              <button className="prev" onClick={showPrevImage}>&#10094;</button>
              <button className="next" onClick={showNextImage}>&#10095;</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Gallery;