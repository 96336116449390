import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import "./teamCarousel.css";

import presidentImage from "../../Assets/Teams/president.JPG";
import Vice from "../../Assets/Teams/Vice.JPG";
import Joette from "../../Assets/Teams/Joette.JPG";
import Samuel from "../../Assets/Teams/Tina.jpeg";
import Grace from "../../Assets/Teams/Grace .JPG";
import Precious from "../../Assets/Teams/Precious.JPG";
import Tina from "../../Assets/Teams/Samuel Andy.JPG";

const TeamCarousel = () => {
  const allTeamMembers = [
    { name: "Mr Andy Azumah Mensah", role: "President", image: presidentImage },
    { name: "Adomaa Sasu-Boamah", role: "Vice President", image: Vice },
    { name: "Precious Akeredolu", role: "Finance and Event Coordinator", image: Precious },
    { name: "Tina Tawiah", role: "Procurement Coordinator", image: Samuel },
    { name: "Samuel Nii Addy", role: "Photography, Research, and Design", image: Tina },
    { name: "Grace Donkor", role: "Marketing and Operations", image: Grace },
    { name: "Joette Agbobli", role: "Secretary", image: Joette },
  ];

  const [isScrollingDown, setIsScrollingDown] = useState(true);

  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = () => {
      if (window.pageYOffset > lastScrollTop) {
        setIsScrollingDown(true);  // scrolling down
      } else {
        setIsScrollingDown(false); // scrolling up
      }
      lastScrollTop = window.pageYOffset;
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const topRowMembers = allTeamMembers.slice(0, 4);
  const bottomRowMembers = allTeamMembers.slice(4);

  return (
    <div className="team">
      <div className="team-carousel-container">
        <div className="team-grid">
          {topRowMembers.map((member, index) => (
            <TeamMember key={index} member={member} index={index} />
          ))}
        </div>
        <div className={`team-grid bottom-row ${isScrollingDown ? "show" : "show"}`}>
          {bottomRowMembers.map((member, index) => (
            <TeamMember key={index} member={member} index={index + 4} />
          ))}
        </div>
      </div>
    </div>
  );
};

const TeamMember = ({ member, index }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 });

  return (
    <div
      ref={ref}
      className={`team-member ${inView ? "animate" : ""}`}
      style={{ animationDelay: `${index * 0.2}s` }}
    >
      <img src={member.image} alt={`${member.name} - ${member.role}`} className="team-image" />
      <h5 className="team-name">{member.name}</h5>
      <p className="team-role">{member.role}</p>
    </div>
  );
};

export default TeamCarousel;
