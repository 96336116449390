import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loader from '../../Loader/loader';
import CarouselDonate from '../../CarouselDonate/CarouselDonate';
import DonateForm from '../../DonateForm/DonateForm';
// import TeamCarousel from '../../TeamCarousel/teamCarousel';
import './donate.css';
// import Aboutimage from "../../../Assets/Images/Gallery/gallery-1.jpg"
// import backgroundImage from '../../../Assets/Images/bg-txt.jpg';

const About = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(loadingTimeout);
  }, []);

  return (
    <div className='about'>
      {loading ? (
        <Loader />
      ) : (
        <div className="about-container">
          <CarouselDonate />

          <div className="Donate-Form">
            <DonateForm />
          </div>
        </div>

      )}
    </div>
  );
};

export default About;
